import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/react"
import RSSIcon from "../assets/rss.svg"
import Layout from "../components/Layout"
import BlogItem from "../components/BlogItem"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import moment from "moment"
import Navbar from "../components/Navbar"
import { shuffle } from "lodash"
import SEO from "../components/seo"
import axios from "axios"
import ListItem from "../components/ListItem"
import PostItem from "../components/PostItem"

export default function PostList({
  data,
  pageContext: { currentPage, skip, numPages },
}) {
  const {
    allAirtable: { edges },
  } = data

  const [email, setEmail] = useState("")
  const [subscribed, setSubscribed] = useState(false)

  const onSubscribe = () => {
    if (email) {
      axios
        .post("https://slashblogs.com/subscribe", {
          email,
          tag: "weeklydigest",
        })
        .then(function (res) {
          setEmail("")
          console.log(res)
          setSubscribed(true)
        })
        .catch(function (err) {
          console.error(err)
        })
    }
  }

  return (
    <Layout>
      <SEO title="Home" />

      <div
        css={css`
          margin-bottom: 80px;
        `}
      >
        <div
          css={css`
            text-align: center;
            margin-bottom: 20px;
            margin-top: 40px;
          `}
        >
          <h1
            css={css`
              margin-bottom: 0;
            `}
          >
            Latest Blog Posts
          </h1>
        </div>
        <div
          css={css`
            margin: 0 auto;
            max-width: 800px;
          `}
        >
          {edges.map((edge, i) => {
            const {
              node: { data },
            } = edge
            return <PostItem data={data} index={skip + i} />
          })}
          <div
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: center;

              a {
                margin: 0 20px;
                font-size: 18px;
              }
            `}
          >
            <Link to={currentPage === 2 ? "/" : `/posts/${currentPage - 1}`}>
              Previous
            </Link>
            {currentPage < numPages && (
              <Link to={`/posts/${currentPage + 1}`}>Next</Link>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PostListPageQuery($skip: Int!, $limit: Int!) {
    allAirtable(
      filter: {
        data: {
          Flag: { ne: true }
          RSS: { ne: "no" }
          Last_Post_Description: { ne: null }
          Last_Post_Date: { ne: null }
          dkb_review: { eq: "approved" }
        }
        table: { eq: "main" }
      }
      sort: { order: DESC, fields: data___Last_Post_Timestamp }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          data {
            Name
            URL
            RSS
            Flag
            NotCodingSpecific
            Last_Post_Date
            Last_Post_Title
            Last_Post_URL
            Last_Post_Description
            Tags
          }
        }
      }
    }
  }
`
